<template>
  <div></div>
</template>

<script>
export default {
  name: 'VbRestApi',
}
</script>

<style></style>
